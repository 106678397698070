import React, { useState } from "react";
import { ArrowBarLeft, Trash, PencilSquare } from "react-bootstrap-icons";
import InvoiceCrud from "../Finance/Invoices/InvoiceCrud";
import PaymentCrud from "../Finance/Payments/PaymentCrud";
import PatientInvoice from "./PatientInvoice";
import PatientPayment from "./PatientPayment";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GeneratePdf from "../../Utils/GeneratePdf";

const PatientFinance = () => {
  const params = useParams();
  const Navigate = useNavigate();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );
  const Ins = useSelector((state) => state.insComp.insComps).filter(
    (ins) => parseInt(ins.deleted) === 0
  );

  const Patient = useSelector((state) => state.cred.creds).find(
    (patient) => parseInt(params.patientLinker) === parseInt(patient.linker)
  ) || { linker: params.patientLinker };

  const Invoices = useSelector((state) => state.invoice.invoices).filter(
    (record) =>
      record.deleted == 0 &&
      parseInt(record.patientLinker) === parseInt(params.patientLinker)
  );

  const Payments = useSelector((state) => state.payment.payments).filter(
    (record) =>
      record.deleted == 0 &&
      parseInt(record.patientLinker) === parseInt(params.patientLinker)
  );

  const [Invoice, setInvoice] = useState({});
  const [Payment, setPayment] = useState({});
  const [InvoiceCrudModal, setInvoiceCrudModal] = useState(false);
  const [PaymentCrudModal, setPaymentCrudModal] = useState(false);
  const [Type, setType] = useState("edit");
  const [InvoiceModal, setInvoiceModal] = useState(false);
  const [PaymentModal, setPaymentModal] = useState(false);

  const Records = [
    ...Invoices.map((invoice) => ({ ...invoice, type: "invoice" })),
    ...Payments.map((payment) => ({ ...payment, type: "payment" })),
  ].sort((a, b) => parseInt(a.linker) - parseInt(b.linker));

  const FindMode = (linker) => {
    let mode = [...Modes, ...Ins].find(
      (mode) => parseInt(mode.linker) === parseInt(linker)
    );

    return mode || { name: "unknown" };
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-around my-2">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => Navigate(-1)}
          >
            <ArrowBarLeft></ArrowBarLeft> Go Back
          </button>
          {parseInt(ActiveCred.admin) === 1 ||
          parseInt(ActiveCred.finance) === 1 ? (
            <>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setInvoiceModal(true)}
                style={{ cursor: "pointer" }}
              >
                Invoice
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setPaymentModal(true)}
                style={{ cursor: "pointer" }}
              >
                Pay
              </button>
            </>
          ) : null}
        </div>
        <div id="patient-bills">
          <p className="h6 text-center">
            {Patient.patientReg} {Patient.firstname} {Patient.lastname}{" "}
            {Patient.surname} Bill Statement
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Details</th>
                <th>Payments</th>
                <th>Invoices</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Records.map((record, index) => (
                <tr key={index}>
                  <td>
                    {new Date(parseInt(record.linker)).toLocaleDateString(
                      "en-US"
                    )}
                  </td>

                  <td>
                    {record.type === "payment"
                      ? FindMode(record.mode).name
                      : null}{" "}
                    {record.code} {record.name} {record.details}
                  </td>
                  <td>{record.type === "payment" ? record.amount : null}</td>
                  <td>{record.type === "invoice" ? record.amount : null}</td>
                  {parseInt(ActiveCred.admin) === 1 ||
                  parseInt(ActiveCred.finance) === 1 ? (
                    <td>
                      <PencilSquare
                        onClick={() => {
                          if (record.type === "payment") {
                            setPayment({ ...record });
                            setPaymentCrudModal(true);
                          } else {
                            setInvoice({ ...record });
                            setInvoiceCrudModal(true);
                          }

                          setType("edit");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-info mx-2"
                      />

                      <Trash
                        onClick={() => {
                          if (record.type === "payment") {
                            setPayment({ ...record });
                            setPaymentCrudModal(true);
                          } else {
                            setInvoice({ ...record });
                            setInvoiceCrudModal(true);
                          }
                          setType("delete");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-danger mx-2"
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
              <tr>
                <th colSpan={2}>Total</th>
                <th>{Payments.reduce((a, b) => +a + +b.amount, 0)}</th>
                <th>{Invoices.reduce((a, b) => +a + +b.amount, 0)}</th>
              </tr>
              <tr>
                <th colSpan={3}>Balance</th>
                <th>
                  {Invoices.reduce((a, b) => +a + +b.amount, 0) -
                    Payments.reduce((a, b) => +a + +b.amount, 0)}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <GeneratePdf
        id={"patient-bills"}
        name={`${Patient.patientReg}-${Patient.firstname}-${Patient.lastname}-${Patient.surname} Bill Statement`}
      ></GeneratePdf>

      <InvoiceCrud
        setShowModal={setInvoiceCrudModal}
        ShowModal={InvoiceCrudModal}
        invoice={Invoice}
        setInvoice={setInvoice}
        type={Type}
      ></InvoiceCrud>
      <PaymentCrud
        setShowModal={setPaymentCrudModal}
        ShowModal={PaymentCrudModal}
        payment={Payment}
        setPayment={setPayment}
        type={Type}
      ></PaymentCrud>
      <PatientInvoice
        patient={Patient}
        InvoiceModal={InvoiceModal}
        setInvoiceModal={setInvoiceModal}
      ></PatientInvoice>
      <PatientPayment
        patient={Patient}
        PaymentModal={PaymentModal}
        setPaymentModal={setPaymentModal}
      ></PatientPayment>
    </>
  );
};

export default PatientFinance;
