import React from "react";
import logo from "../assets/images/health-management-tech-system-logo.jpg";
import { MenuButton } from "react-bootstrap-icons";
import PwaInstall from "../Utils/PwaInstall";

const Header = ({ ToggleDrawer }) => {
  return (
    <div className="bg-secondary header" style={{ color: "white" }}>
      {ToggleDrawer ? (
        <div className="d-flex justify-content-between">
          <button className="navbar-toggler">
            <MenuButton
              className="navbar-toggler-icon"
              onClick={() => ToggleDrawer()}
            ></MenuButton>
          </button>
          <p className="navbar-brand h5">Health Techsystem </p>
          <div className="">
            <PwaInstall></PwaInstall>{" "}
            <img
              src={logo}
              alt="health-management-tech-system-library-logo"
              className="rounded"
              style={{ maxHeight: "40px", width: "auto" }}
            />
          </div>
        </div>
      ) : (
        <div className=" d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {" "}
            <img
              src={logo}
              alt="health-management-tech-system-library-logo"
              className="rounded "
              style={{ maxHeight: "40px", width: "auto" }}
            />
            <p className="navbar-brand h5 mx-2">Health Techsystem </p>
          </div>

          <PwaInstall></PwaInstall>
        </div>
      )}
    </div>
  );
};

export default Header;
