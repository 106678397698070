import React from "react";

const NoInvoices = () => {
  return (
    <tbody>
      <tr>
        <th>No Invoices</th>

        <td>
          Invoices are financial obligations that students owe the institution
          eg tuition fee, accomodation fee etc
        </td>
      </tr>
    </tbody>
  );
};

export default NoInvoices;
